export default function sendEmail() {
  var name = document.getElementById("name").value
  var email = document.getElementById("email").value
  var phone = document.getElementById("phone").value
  var message = document.getElementById("message").value

  const url =
    "https://us-central1-phonefix-pro.cloudfunctions.net/contactFormSending"
  const GEN_KEY = "ZrkY0kzbfDCEUS6ZkcTzz7ff4UnXgqGd"
  const params = {
    name: name,
    email: email,
    phone: phone,
    message: message,
    gen_key: GEN_KEY,
  }

  fetch(`${url}?${new URLSearchParams(params).toString()}`)
    .then(resp => resp.json())
    .then(data => {
      console.log(data)
      alert("Your request was sent successful!")
      window.location.reload()
    })
    .catch(error => {
      console.log(error)
      alert(
        "There was an Error Sending your request. Please Submit the form Again"
      )
      // location.reload();
    })
}

