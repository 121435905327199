import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/styles.css"
import sendEmail from "../components/contactSendingScript.js"
import mapImg from "../images/interactiveMapShot.jpg"
import mapImgMobile from "../images/interactiveMapShotMobile.jpg"

const ContactPage = ({ location }) => {
  const handleSubmit = event => {
    event.preventDefault()
  }

  const handleFormSend = () => {
    const phone = document.getElementById("phone").value
    const phonemo = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

    phone.match(phonemo) ? sendEmail() : alert("Incorrect Phone Number")
  }

  var serviceCity
  if (location.state) {
    serviceCity = location.state.serviceCity
  } else {
    serviceCity = "Portland, OR"
  }
  return (
    <Layout
      serviceCity={serviceCity}
      currentPage="contact"
      uniqueHeader={"Contact iPhone Repair  " + serviceCity}
    >
      <SEO
        title="Contact PhoneFix PRO"
        description="Contact PhoneFix PRO and get your iPhone Repair and Android Repair questions answered. We are located in Portland, OR inside Lloyd Center Mall."
      />
      <div className="contactMainGrid">
        <div className="contactFormWrapper">
          <h2 className="contactFormHeader">Get in touch with Us</h2>
          <div className="contactFormLine"></div>
          <form type="POST" onSubmit={handleSubmit}>
            <label htmlFor="name" className="contactFormLabel">
              Your Name (required)
            </label>
            <input
              type="text"
              required
              className="contactFormInput"
              id="name"
            />
            <label htmlFor="email" className="contactFormLabel">
              Your Email (required)
            </label>
            <input
              type="email"
              required
              className="contactFormInput"
              id="email"
            />
            <label htmlFor="phone" className="contactFormLabel">
              Your Phone (required)
            </label>
            <input
              type="phone"
              required
              className="contactFormInput"
              id="phone"
            />
            <label className="contactFormLabel" htmlFor="message">
              Your Message
            </label>
            <textarea className="contactFormTextArea" id="message" />
            <button className="contactFormButton" onClick={handleFormSend}>
              SUBMIT
            </button>
          </form>
        </div>
        <div className="contactInfoWrapper">
          <div className="contactAdressInfoWrapper">
            <div className="contactAdressInfoWrapperText">
              <h2 className="contactHeader">
                PhoneFix PRO Android & iPhone Repair
              </h2>
              <div className="contactInfoFlexWrapper">
                <div className="contactInfoAdressWrapper">
                  <p className="contactPar">
                    Address: 2201 Lloyd Center, <br /> Portland, OR 97232
                  </p>
                  <p className="contactPar">
                    Phone: <a href="tel:9713193194">(971) 319-3194</a>
                  </p>
                </div>
                <div className="contactInfoNoteWrapper">
                  <p className="contactPar">
                    Note: We are located inside Lloyd Center Mall By Ice Rink on
                    1st Floor next to T-Mobile.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="contactMapDivWrapper">
            <div className="mapWrapper">
              <iframe
                src="https://embed.apps.webstarts.com/maps/google.php?q=phonefix%20pro%20lloyd&amp;mode=driving&amp;map=place&amp;units=metric&amp;maptype=roadmap&amp;zoom=15&amp;key=AIzaSyDQjY4BtQqsereuYYEgT-m-gc0k_OMdQfU"
                frameBorder="0"
                title="PhoneFix PRO iPhone Repair Google Maps"
                className="contactMap"
              ></iframe>
            </div>
            <div className="contactInnerMapWrapper">
              <img
                src={mapImg}
                className="contactInnerMapImg contactDesktopMapImg"
                alt="PhoneFix PRO Broken iPhone Repair Shop Mall Map"
              />
              <img
                src={mapImgMobile}
                className="contactInnerMapImg contactMobileMapImg"
                alt="PhoneFix PRO Cracked iPhone Repair Store Mall Map"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default ContactPage
